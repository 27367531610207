.OurPlane-content {
    display: flex;
    margin-top: 40px;
    justify-content: space-around;
}
.OurPlane {
    background: #f0f8ff;
    background: #f0f8ff;
    margin-top: 182px;
    padding: 70px 0px;
}

.OurPlane-content-box {
    text-align: left;
    background: white;
    width: 31%;
    padding: 40px;
    box-shadow: -1px 3px 7px 0px rgb(0 0 0 / 27%);
    border-radius: 10px;
    transition: all 0.4s ease-out;
}

.OurPlane-content-box:hover {
    transform: scale(1.1);
}

.OurPlane h2 {
    font-size: 45px;
    font-weight: 600;
    
}
.OurPlane-content-box h5 {
    margin-bottom: 30px;
    font-size: 25px;
    color: #198754;
    text-align: center;
}
.OurPlane-content-box li {
    margin-bottom: 6px;
    /* font-size: 16px; */
}

li::marker {
    color: #198754 !important; }

    .OurPlane-content-box button.my-bttn.btn.btn-danger {
        margin-left: 33px;
    }

    .OurPlane-content-box ul {
        min-height: 275px;
    }

    .OurPlane-imgbox {
        width: 70px;
    }
    .OurPlane-content-box .OurPlane-content-imgbox {
        display: flex;
        justify-content: center;
        padding-left: 35px;
        margin-bottom: 18px;
    }
    .OurPlane-content-box .OurPlane-content-imgbox img {
        max-width: 70px;
     
        height: 70px;
    }
    @media only screen and (max-width:  767px) {
       
        .OurPlane-content {
            display: grid;
           
        }
        .OurPlane-content-box {
            width: 100%;
            margin-bottom: 35px;
        }
        .OurPlane {
           
            background: #f0f8ff;
            margin-top: 40px;
            padding: 60px  0px;
         
        }
        .OurPlane-content-box ul {
            min-height: auto;
        }

        }

        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px){

            .OurPlane-content {
                display: grid;
               
            }
            .OurPlane-content-box {
                width: 100%;
                margin-bottom: 35px;
            }
            .OurPlane {
               
                background: #f0f8ff;
                margin-top: 40px;
                padding: 60px  0px;
             
            }
            .OurPlane-content-box ul {
                min-height: auto;
            }

        }