.text-center.mt-4.Introductiomn-heading.col h2 {
    
    font-size: 48px;
    font-weight: 600;
}
.Introductiomn {
    
    margin-top: 40px;
   
 
}

.Introductiomn-box.row img {
    width: 100%;
    height: 265px;
    object-fit: cover;
}


.img-content-box {
    height: auto;
    background-color: #9e9e9e21;
    padding: 15px 10px;
    margin-top: 15px;
    height: 151px;
    box-sizing: border-box;
}


.line{
    position: relative;
    margin-bottom: 20px;
}
.line:after {
    position: absolute;
    content: "";
    height: 3px;
    background-color: #198754;
    width: 13%;
    /* margin-left: 15px; */
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
}
.img-content-box.text-center h3.line {
    color: #198754;
    font-weight: 600;
}


      
@media only screen and (max-width:  767px) {
    .text-center.mt-4.Introductiomn-heading.col h2 {
        
        font-size: 30px;
       
    }

    .Introductiomn {
        
        margin-top: 20px;
        padding: 14px 0px;
        clip-path: inherit;
    }
   
    .Introductiomn .img-content-box.text-center {
        margin-bottom: 40px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px){

    .text-center.mt-4.Introductiomn-heading.col h2 {
        
        font-size: 30px;
       
    }

    .Introductiomn {
        
        margin-top: 20px;
        padding: 14px 0px;
        clip-path: inherit;
    }
   
    .Introductiomn .img-content-box.text-center {
        margin-bottom: 40px;
        height: 190px;
    }

}