.home-bnner.carousel-item {
    height: calc(100vh - 220px) !important;
    /* height: 550px; */
   overflow: hidden;
 

}
.home-bnner.item1.carousel-item{
    background-image: url(../../../assets/img/banner-img-3-1.jpg);
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
}
.home-bnner.item2.carousel-item{
    background-image: url(../../../assets/img/hero_banner_V2.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
}
.home-bnner.item3.carousel-item{
    background-image: url(../../../assets/img/panneaux_solaires.jpg);
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
}
.energy-text.for-deskstop .slider-lower-box.text-center a {
    text-decoration: none;
    color: #198754;
}

.w-90 {
    width: 82% !important;
}
.carousel-item img {
   
   
   width: 100% ;
    transition: all 0.4s; 
   

}
.energy-text.for-deskstop {
    margin-top: -87px;
}
.carousel-item img:hover{
    scale: 1.2;
}

.slideLeft.carousel-caption {
    position: absolute;
    top: 20% !important;
    text-align: left !important;
}
.carousel-indicators {
    right: 63% !important;
}
.carousel-caption h1 {
    font-size: 54px;
    font-weight: 800;
}
.home-bnner.carousel-item .my-bttn {
    margin-top: 20px !important;
}
.for-mob {
    display: none !important;
}

.w-60.slideLeft.carousel-caption {
   
    width: 100%;
}
.slideLeftt {
    animation-duration: 2000ms;
    animation-name: slideLeftt;
    animation-fill-mode: forwards;
  }
  
.slideRight {
    animation-duration: 2000ms;
    animation-name: slideRight;
    animation-fill-mode: forwards;
  }

@keyframes slideRight {
    0% {
        transform: translate(100%, 0);
        opacity: 0;
    }

    100% {
        transform: translate(0%, 0);
        opacity: 1;
    }
}

@keyframes slideLeftt {
    0% {
        transform: translate(-100%, 0);
        opacity: 0;
    }

    100% {
        transform: translate(0%, 0);
        opacity: 1;
    }
}



@keyframes updown {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}










.carousel-indicators [data-bs-target] {
  
   display: none !important;
   
  }

  .energy-text {
  
   
    display: flex;
    width: 80%;
    /* right: 50%; */
    transform: translate(12%);
    justify-content: center;
}

.energy-text h1 {
    color: rgba(255,255,255, 0);
    font-size: 160px;
    text-transform: uppercase;
    line-height: 1em;
    letter-spacing: 35px;
    -webkit-text-stroke: 1px #ffffff;
}
.slider-lower-box {
    width: 24%;
    height: 125px;
    background: white;
    margin-left: 2px;
    box-shadow: 0 0 19px rgb(0 0 0 / 10%);
    padding-top: 14px;
    transition: all 0.2s ease-out;
}
.slider-lower-box:hover {
  scale: 1.1;
}

.slider-lower-box img {
    width: 25%;
}

@media only screen and (max-width:  767px) {
    .slideLeftt h1 {
        font-size: 21px;
    }
    .slideLeftt p {
        padding: 0;
        margin: 0;
    }
    .for-mob {
        display: block !important;
    }

    .home-bnner.carousel-item {
        height: 540px !important;
        /* height: 550px; */
    }
    .energy-text .slider-lower-box.text-center {
        margin-bottom: 15px  !important;
    }
    .home-bnner.carousel-item .my-bttn {
        margin-top: 8px !important;
    }
    .slideLeft.carousel-caption .row {
        flex-direction: column-reverse;
    }
    .slideRight img.d-block.w-100 {
        width: 65% !important;
    }
    .slideLeftt h1 br {
        display: none;
    }
    .slideLeft.carousel-caption {
        top: 4% !important;
    width: 100%;
    left: 0;
    padding: 0 15px;
    }
    
    .for-deskstop {
        display: none !important;
    }
    .slideLeftt {
        margin-top: 14px !important;
    }
    .slider-lower-box {
        height: auto;
        background: #0000005c;
        padding-top: 13px;
        /* PADDING-BOTTOM: 10PX; */
        padding-bottom: 10px;
        backdrop-filter: blur(9px);
    }
    .slider-lower-box.text-center h5 {
        font-size: 14px;
    }

    .home-bnner.carousel-item {
       
        clip-path: inherit;
    }

    .energy-text {
        display: flex;
        width: 100%;
       
        transform: inherit;
       
        flex-wrap: wrap;
        margin-top:80px;    }

    .slider-lower-box {
        width: inherit;
       flex: 0 0 49%;
    }

.w-60.slideLeft.carousel-caption {
    width: 90%;
}


.slider-lower-box h5 {
    font-size: 16px;
}

.w-90 {
    width: 57% !important;
}

.bnner-slider span.carousel-control-prev-icon {
    display: none;
}
.bnner-slider span.carousel-control-next-icon {
    
   
    display: none !important;
}
.for-mob .energy-text .slider-lower-box.text-center a {
    text-decoration: none;
    color: #198754;
}


  }