.ContactBanner {
    height: calc(100vh - 220px) !important;
    background-image: url(../../../assets/img/panneaux_solaires.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
text-align: center;
    padding: 80px 0px;
    display: flex;
    align-items: center;
}

.ContactBanner-left h2 {
    color: #fff;
    font-size: 96px;
    border-bottom: 4px solid #ffffff8a;
    /* opacity: 0.4; */
    text-shadow: 0 0 3px #4091b1, 0 0 0px #ffffff;
    display: inline-block;
}
.ContactBanner-Right img {
    width: 100%;
    animation: updown 1s infinite alternate;
}
.ContactBanner .row {
    align-items: center;
}
.ContactBanner-left {
    display: flex;
    justify-content: center;
    /* align-items: center; */
}
.ContactBannerAppsulate {
    text-align: center;
}
.ContactBannerAppsulateText {
    position: relative;
    z-index: 9;
    height: 125px;
    background: white;
    margin-left: 2px;
    box-shadow: 0 0 19px rgb(0 0 0 / 10%);
    padding-top: 14px;
    transition: all 0.2s ease-out;
    margin-top: -40px;
    
    padding-top: 40px;
}

.ContactBannerAppsulateText p {
    font-size: 27px;
    margin-top: 10px;
    animation: updown 1s infinite alternate;
}

form {
    width: 40%;
}
.ContactForm .container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: -100px;
    box-shadow: -1px 3px 7px 0px rgb(0 0 0 / 27%);
    padding: 40px;
    position: relative;
    background: white;
    transition: all 0.2s ease-out;
    margin-bottom: 80px;
}
.modal.show .modal-dialog {
   
    position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  
    width: 100%;
}

@media only screen and (max-width:  767px) {
    .ContactBanner {
       
        clip-path: inherit;
    }
    .ContactBanner-left h2 {
        
        font-size: 60px;
      
    }
    .ContactBanner {
        clip-path: inherit;
        height: 385px !important;
        padding: 130px 0px;
    }
    .ContactBannerAppsulateText {
       
        display: inherit;
        height: 138px;
        padding-top:15px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .ContactForm .container {
        display: inherit;
        width: 90%;
        
    margin-top: -68px;
       
        padding: 40px;
    }
    form {
        width: 100%;
    }
    .googleMap iframe {
        margin-top: 20px;
    }
  }