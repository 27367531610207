.AboutBanner {
    /* height: 400px; */
    height: calc(100vh - 220px) !important;
    background-image: url(../../../assets/img/banner-img-3-1.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
text-align: center;
    padding: 80px 0px;
    display: flex;
    align-items: center;
}

.AboutBanner-left h2 {
    color: #fff;
    font-size: 96px;
    border-bottom: 4px solid #ffffff8a;
    /* opacity: 0.4; */
    text-shadow: 0 0 3px #4091b1, 0 0 0px #ffffff;
    display: inline-block;
}
.AboutBanner-Right img {
    width: 100%;
    animation: updown 1s infinite alternate;
}

.AboutBannerAppsulate {
    text-align: center;
}
.AboutBannerAppsulateText {
    position: relative;
    z-index: 9;
    height: 125px;
    background: white;
    margin-left: 2px;
    box-shadow: 0 0 19px rgb(0 0 0 / 10%);
    padding-top: 14px;
    transition: all 0.2s ease-out;
    margin-top: -40px;
    display: flex;
    justify-content: space-around;
    padding-top: 40px;
}
.AboutBannerAppsulateText:hover {
    scale: 1.1;
}

@media only screen and (max-width:  767px) {
    .AboutBanner {
       
        clip-path: inherit;
    }
    .AboutBanner-left h2 {
        
        font-size: 60px;
      
    }
    .AboutBanner {
        clip-path: inherit;
        height: 385px !important;
        padding: 130px 0px;
    }
    .AboutBannerAppsulateText {
       
        display: inherit;
        height: 138px;
        padding-top:15px;
        padding-left: 10px;
    padding-right: 10px;
    }
  }