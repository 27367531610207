
.App-logo {
  width:100px;
  pointer-events: none;
}

.my-bttn {
  background-color: #198754 !important;
  height: 40px;
  width: 205px;
  box-shadow: -1px 3px 7px 0px rgb(0 0 0 / 27%);
  border: none !important;
  
}

html, body{
  overflow-x: hidden;
}

@media only screen and (max-width:  767px) {
  .App-logo {
    width: 80px;
    pointer-events: none;
  }
  .my-bttn {
    background-color: #198754 !important;
    height: 35px;
    width: 124px;
    box-shadow: -1px 3px 7px 0px rgb(0 0 0 / 27%);
    border: none !important;
    font-size: 14px !important;
    color: #198754;
}
  }




