body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@font-face {
  font-family: 'poppins';
  src: local('poppins'), url(./assets/fonts/Poppins-Regular.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}


a,p,span,ul, li{
  font-family: poppins, serif;
 
}