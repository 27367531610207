.me-auto.navbar-nav a.nav-link {
    
    font-size: 15px;
}
.navbar-expand-lg .navbar-nav {
    flex-direction: row;
    width: 100%;
    justify-content: end;
}

.me-auto.navbar-nav a {
    margin-left: 23px;
    margin-right: 23px;
} 
nav.navbar.navbar-expand-lg.navbar-light {
    padding: 0;
}
.header-button button.btn.btn-danger {
    background-color: #f6a235;
}

.Talk-button {
    position: fixed;
    z-index: 99;
    color: white !important;
    background-color: #ff5400;
    background-color: #6dd629 !important;
    height: 47px;
    width: 140px;
    box-shadow: -1px 3px 7px 0px rgb(0 0 0 / 27%);
    transition: all 0.4s ease-out;
    
}

.Talk-button a {
    line-height: 48px;
    padding-left: 5px;
    color: white;
    text-decoration: none;
    display: flex;
    font-size: 14px;
    
    padding: 0px 7px;
}

.Talk-button span {
    width: 40px;
    display: inline-block;
}
.Talk-button span img {
    width: 70%;
    padding-left: 6px;
}
.Talk-button  a:hover {
    color: white;
}

a.hvr-float.nav-link {
    transition: all 0.4s ease-out;
}





@media only screen and (max-width:  767px) {
    .header-button {
     
        padding-left: 10px;
    }
   .header-button {
    padding-left: 10px;
}
.navbar-expand-lg .navbar-nav {
    flex-direction: column;
    width: 100%;
    justify-content: space-around;
}
.me-auto.navbar-nav a.nav-link {
    margin-left: 10px;
    font-size: 16px;
}
button.navbar-toggler {
    background-color: #198754;
    border-color: #198754;
}
nav.navbar.navbar-expand-lg.navbar-light {
    padding: 0;
}
.collapse.show {
    padding: 25px 0px;
}
span.navbar-toggler-icon {
    font-size: 11px;
}
    }