.Products {
    margin-top: 80px;
}

.Products-right {
    display: flex;
    align-items: center;
    justify-content: end;
}

.Products-right-box-img img {
    width:100%;
}

.Products-right-box {
    position: relative;
}
.text-center.product-imgs img {
    width: 32%;
}
.text-center.product-imgs.product-imgs-1 img {
    width: 16.5%;
}
.text-center.product-imgs p {
    font-weight: 400;
    margin-bottom: 64px;
    margin-top: 15px;
}

.Products-right-box-img {
    position: relative;
    overflow: hidden;
    height: 500px;
    width: 500Px;
    text-align: center;
    margin: 0;
    border-radius: 100%;
    padding: 0;
    border: 4px solid #198754;
    display: flex;
    align-items: stretch;
    justify-content: center;
}

.Products-Heading h2 {
    font-size: 45px;
    margin-bottom: 65px;
    font-weight: 600;
}

.Products-right1 img ,.Products-right2 img ,.Products-right3 img ,.Products-right4 img {
    width: 100%;
}
.Products-right1, .Products-right2, .Products-right3, .Products-right4 {
    width: 150px;
    height: 150px;
    display: inline-block;
    overflow: hidden;
    border-radius: 50%;
    background-color: aqua;
    border: 4px solid #198754;
    animation: updown 1s infinite alternate;
}
.Products-right3 {
    position: absolute;
    top: -19px;
    left: 0;
}

.Products-right1 {
    position: absolute;
    top: 285px;
    right: -50px;
}

.Products-right2 {
    position: absolute;
    bottom: -97px;
    left: 163px;
}

.Products-right4 {
    position: absolute;
    top: 0;
    right: 0;
}







@keyframes updown {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}



@media only screen and (max-width:  767px) {
    

    .Products .align-items-center.row {
        flex-direction: column-reverse;
    }
    .Products-right {
        /* display: flex; */
        margin-left: 0px;
    }
    .Products-right-box {
        /* height: 300px; */
        /* width: 300px; */
        /* border: 4px solid #6dd629; */
        text-align: center;
        margin: auto;
    }

    .Products-right-box-img {
       
        height: 300px;
        width: 300px;
        
    }
    .Products-right1, .Products-right2, .Products-right3, .Products-right4 {
        width: 90px;
        height: 90px;
      
    }
    .Products-right2 {
        bottom: -13px;
        left: 23px;
    }
    .Products-right1 {
        position: absolute;
        top: 176px;
        right: -14px;
    }
    .Products-right3 {
        /* top: 26px; */
        position: absolute;
        top: -28px;
        left: 22px;
    }
    .Products-left {
        position: relative;
        padding-left: 0px;
        margin-top: 27px;
    }
   
    .Products-Heading h2 {
        font-size: 31px;
        margin-bottom: 65px;
        font-weight: 600;
    }
   .Products-right3 {
        position: absolute;
        left: 0;
    }
    }


    @media only screen and (max-width:  767px) {
    

        .Products .align-items-center.row {
            flex-direction: column-reverse;
        }
        .Products-right {
            /* display: flex; */
            margin-left: 0px;
        }
        .Products-right-box {
            /* height: 300px; */
            /* width: 300px; */
            /* border: 4px solid #6dd629; */
            text-align: center;
            margin: auto;
        }
    
        .Products-right-box-img {
           
            height: 300px;
            width: 300px;
            
        }
        .Products-right1, .Products-right2, .Products-right3, .Products-right4 {
            width: 90px;
            height: 90px;
          
        }
        .Products-right2 {
            bottom: -13px;
            left: 23px;
        }
        .Products-right1 {
            position: absolute;
            top: 176px;
            right: -14px;
        }
        .Products-right3 {
            /* top: 26px; */
            position: absolute;
            top: -28px;
            left: 22px;
        }
        .Products-left {
            position: relative;
            padding-left: 0px;
            margin-top: 27px;
        }
       
        .Products-Heading h2 {
            font-size: 31px;
            margin-bottom: 65px;
            font-weight: 600;
        }
       .Products-right3 {
            position: absolute;
            left: 0;
        }
        }


        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px){


            .Products .align-items-center.row {
                flex-direction: column-reverse;
            }
            .Products-right {
                /* display: flex; */
                margin-left: 0px;
            }
            .Products-right-box {
                /* height: 300px; */
                /* width: 300px; */
                /* border: 4px solid #6dd629; */
                text-align: center;
                margin: auto;
            }
        
            .Products-right-box-img {
               
                height: 300px;
                width: 300px;
                
            }
            .Products-right1, .Products-right2, .Products-right3, .Products-right4 {
                width: 90px;
                height: 90px;
              
            }
            .Products-right2 {
                bottom: -13px;
                left: 23px;
            }
            .Products-right1 {
                position: absolute;
                top: 176px;
                right: -14px;
            }
            .Products-right3 {
                /* top: 26px; */
                position: absolute;
                top: -28px;
                left: 22px;
            }
            .Products-left {
                position: relative;
                padding-left: 0px;
                margin-top: 27px;
            }
           
            .Products-Heading h2 {
                font-size: 31px;
                margin-bottom: 65px;
                font-weight: 600;
            }
           .Products-right3 {
                position: absolute;
                left: 0;
            }



        }