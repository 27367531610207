.AboutContent-Right img {
    width: 100%;
    border-radius: 30px;
}

.AboutContent {
    margin: 120px 0px;
}

.AboutContent-left h2 {
    font-size: 48px;
    margin-bottom: 20px;
}

.AboutContent-left h2 span.green {
    color: #6dd629;
    font-weight: bold;
    /* margin-bottom: 49px; */
}
.AboutContent-left h2 span.Blue {
    color: #2e329d;
    font-weight: bold;
}


.about-installed {
    margin-bottom: 220px;
}


@media only screen and (max-width:  767px) {
    .AboutContent {
        margin: 80px 0px;
    }   

}