.apslute-footer {
    margin-top: 0;
    background-color: #ebebeb;
    padding: 40px 11px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 99;
    box-shadow: -1px 3px 7px 0px rgb(255 255 255 / 56%);
}
.apslute-footers {
    background: aliceblue;
}
.apslute-footer .text h2 {
    font-size: 48px;
}
.apslute-footer .text{
    width: 60%;
   
}

.Footer {
    background-color: black;
    height: auto;
    padding: 120px;
    margin-top: -60px;
}

.Footer a, .Footer p, .Footer h5 {
   
    color: white;
    text-decoration: none;
}
.Footer a:hover {
    color: #198754 !important;
}
.Footer-containt {
    align-items: baseline;
}


.Footer-2-sec .Footer-text,.Footer-3-sec .Footer-text,.Footer-4-sec .Footer-text {
    margin-top: 55px;
}

.Footer-1-sec .Footer-text {
    margin-top: 13px;
}
.Footer-4-sec .Footer-text .social {
    width: 40px;
    height: 40px;
    background: azure;
    /* display: none; */
    margin-left: 5px;
    border-radius: 50%;
    display: inline-block;
    line-height: 40px;
    text-align: center;
    transition: all 0.4s;
}


.Footer-4-sec .Footer-text a  svg {
    fill: #198754;
    width: 25px;
}
@media only screen and (max-width:  767px) {
    .apslute-footer {
       
        display: grid;
     
        z-index: 99;
       
        text-align: center;
    }
    .apslute-footer .text {
        width: 100%;
    }
    .apslute-footer .text h2 {
        font-size: 30px;
    }
    .Footer {
        background-color: black;
        height: auto;
        padding: 0;
        margin-top: -50px;
        padding-bottom: 17px !important;
    }
    .Footer {
        background-color: black;
        height: auto;
        padding: 0;
        margin-top: -50px;
        padding-top: 70px;
    }
    .Footer-4-sec .Footer-text .social {
        width: 30px;
        height: 30px;
        line-height: 30px;
        
    }
}
      
@media only screen and (max-width:  320px) {
   
    .Footer-4-sec .Footer-text .social {
        width: 28px;
        height: 28px;
        line-height: 28px;
    }
    .Footer {
       
        padding-bottom: 17px !important;
    }
}