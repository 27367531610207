.apsulteheader {
    height: auto;
    background: #198754;
    /* box-shadow: -1px 3px 7px 0px rgb(0 0 0 / 27%); */
    box-sizing: border-box;
    margin-bottom: 5px;
    padding: 3px 0px;
    line-height: 4px;
    transition: all 500ms cubic-bezier(0.55, 0, 0.1, 1);
  
   
}

.apsulteheader-inner {
    align-items: center;
    display: flex;
    justify-content: space-between;
}
.apsulteheader-inner a {
    color: beige;
    text-decoration: none;
    font-weight: 700;
}

.apsulteheader-inner a span {
    padding-right: 10px;
}
.my-bttn2 {
    background-color: #ffffff !important;
    height: 26px;
    width: 127px;
    box-shadow: -1px 3px 7px 0px rgb(0 0 0 / 27%);
    border: none !important;
    color: #198754 !important;
    margin-left: 18px;
    font-size: 13px !important;
    line-height: 14px !important;
    
}


.sticky{
    position: fixed;
    width: 100%;
    top: -100px;
    height: 50px;
    z-index: 99;
    box-shadow: 1px 1px 10px rgb(0 0 0 / 15%);
    animation: stuckMoveDown .6s;
}

.apsulteheader.sticky{
    position: fixed;
    width: 100%;
    top: 0;
    height: 50px;
    z-index: 99;
    box-shadow: 1px 1px 10px rgb(0 0 0 / 15%);
    animation: fadeInDown .6s;
    padding-top: 13px;
    
}
.apsulteheader a:hover {
    color: #ffffffc4 !important ;
}
@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px)
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}


@media only screen and (max-width:  767px) {
    .apsulteheader-inner a {
        color: beige;
        text-decoration: none;
        font-weight: 700;
        font-size: 12px;
    }
    .apsulteheader {
      
        margin-bottom: 0px;
     
    }

    .my-bttn2 {
        background-color: #ffffff !important;
        height: 30px;
        width: auto;
        box-shadow: -1px 3px 7px 0px rgb(0 0 0 / 27%);
        border: none !important;
        color: #198754 !important;
        margin-left: 10px;
        padding: 5px !important;
        font-size: 12px !important;
    }
  }



  @media only screen and (max-width: 320px)  {
    .apsulteheader-inner a {
        color: beige;
        text-decoration: none;
        font-weight: 700;
        font-size: 10px;
    }
    .my-bttn2 {
        background-color: #fff!important;
        border: none!important;
        box-shadow: -1px 3px 7px 0 rgb(0 0 0 / 27%);
        color: #198754!important;
        font-size: 12px!important;
        height: auto;
        margin-left: 10px;
        width: auto;
        padding: 5px !important;
        width: inherit;
    }
 

  }
