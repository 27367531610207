.GetForm .ContactForm {
    margin-top: 139px !important;
}

.modal.show .modal-dialog {
   
    position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  
    width: 100%;
}

.spinner-border{
  width: 1em !important;
  height: 1em !important;
}